<script setup lang="ts">
import { computed, watch } from "vue";
import { useAnalyticManager } from "@magnit/analytic-events";
import VModal from "@magnit/core/src/components/VModal/VModal.vue";
import { MODALS, useModalsStore } from "~/store/modals";
import VIosPwaInstruction from "~/components/VIosPwaInstruction.vue";

const modalsStore = useModalsStore();
const { send } = useAnalyticManager();

const isActive = computed(
  () => modalsStore.getActive() === MODALS.PwaIosInstruction,
);

const isOpen = computed(() => modalsStore.isOpen(MODALS.PwaIosInstruction));

const onClose = () => {
  send("App:Ios:Instruction:Popup:Close:Click");
  modalsStore.close(MODALS.PwaIosInstruction);
};

watch(isOpen, (next) => {
  if (next) {
    send("App:Ios:Instruction:Popup:View");
  }
});
</script>

<template>
  <VModal
    :id="MODALS.PwaIosInstruction"
    :active="isActive"
    :open="isOpen"
    :closable="true"
    type="bottom-sheet"
    title="Добавьте В1 на рабочий стол"
    wrapper-class="modal-ios-instruction_main"
    @close="onClose"
  >
    <VIosPwaInstruction
      description-text="Вы сможете быстро заходить в веб-приложение, чтобы пользоваться картой и купонами"
    />
  </VModal>
</template>

<style lang="postcss">
.modal-ios-instruction_main {
  & .pl-modal-header {
    &__control-wrapper {
      min-width: var(--pl-unit-x6);
    }
  }
}
</style>
