<script setup lang="ts">
import { watch } from "vue";
import type { IToast } from "~/components/VToasts/VToasts.types";
import VToasts from "~/components/VToasts/VToasts.vue";
import { useAppStore } from "~/store/app";

const { toasts } = storeToRefs(useAppStore());

const handleClose = (item: IToast) => {
  const key = item.key;
  toasts.value = toasts.value.filter((s) => s.key !== key);
};
watch(toasts, (next) => {
  if (next.length > APP_TOAST_LIMIT) handleClose(next[0]);
});
</script>

<template>
  <section class="notification_manager">
    <VToasts
      v-for="single in toasts"
      v-bind="single"
      :key="single.key"
      @close="() => handleClose(single)"
    />
  </section>
</template>

<style scoped lang="postcss">
.notification_manager {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 20px;
  align-items: center;
  z-index: var(--pl-z-index-overlay);
  height: 0;
  overflow: visible;
}
</style>
