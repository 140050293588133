<script setup lang="ts">
import type { INotification } from "~/components/VNotification/VNotification.types";
import VNotification from "~/components/VNotification/VNotification.vue";
import { useAppStore } from "~/store/app";

const { notifications } = storeToRefs(useAppStore());

const handleClose = (item: INotification) => {
  const key = item.key;
  notifications.value = notifications.value.filter((s) => s.key !== key);
};
</script>

<template>
  <section class="notification_manager">
    <VNotification
      v-for="single in notifications"
      v-bind="single"
      :key="single.key"
      @close="() => handleClose(single)"
    />
  </section>
</template>
