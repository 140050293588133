<script setup lang="ts">
import { computed, watch, ref, onMounted } from "vue";
import { vElementVisibility } from "@vueuse/components";
import { AnalyticManager } from "@magnit/analytic-events/src/manager";
import VButton from "@magnit/core/src/components/VButton/VButton.vue";
import VText from "@magnit/core/src/components/VText/VText.vue";
import CloseIcon from "@magnit/icons/src/assets/icons/close-24.svg";
import { usePwaStore } from "~/store/pwa";
import { usePlatformStore } from "~/store/platform";
import AppLogo from "~/assets/svg/В1_logo.svg";
import { usePwaIosInstructionModal } from "~/composables/useModals";

const pwaStore = usePwaStore();
const platformStore = usePlatformStore();
const iosInstructionModal = usePwaIosInstructionModal();
const { send } = AnalyticManager;

const isElementVisible = ref(false);
const visibilityEventEmitted = ref(false);
const isClosed = ref(false);

const showOpenButton = computed(() => pwaStore.state.appInstalled);
const isShowBanner = computed(
  () =>
    !isClosed.value &&
    (pwaStore.state.installPromptBannerOpen ||
      pwaStore.state.showIosTutorial ||
      showOpenButton.value),
);

const onClose = () => {
  pwaStore.state.installPromptBannerOpen = false;
  pwaStore.state.showIosTutorial = false;
  isClosed.value = true;
};

const onOpenPwaClick = () => {
  send("App:Open:Banner:Click");
  if (platformStore.platformInfo.pwaLinkSupported) {
    window.open(pwaStore.DEFAULT_PWA_LINK, "_blank");
  }
};

const onButton = () => {
  send("App:Prompt:Banner:Click");
  if (pwaStore.state.showIosTutorial) {
    iosInstructionModal.open();
    return;
  }
  pwaStore.showPrompt();
};

const onElementVisibility = (visibility: boolean) => {
  isElementVisible.value = visibility;
};

const onEventsTriggering = () => {
  if (
    pwaStore.state.installPromptBannerOpen ||
    pwaStore.state.showIosTutorial
  ) {
    visibilityEventEmitted.value = true;
    send("App:Prompt:Banner:View");
  } else if (showOpenButton.value) {
    visibilityEventEmitted.value = true;
    send("App:Open:Banner:View");
  }
};

watch(isElementVisible, (next) => {
  if (next && !visibilityEventEmitted.value) {
    onEventsTriggering();
  }
});

watch(showOpenButton, (next) => {
  if (visibilityEventEmitted.value && next) {
    send("App:Open:Banner:View");
  }
});

onMounted(() => {
  if (isElementVisible.value) {
    onEventsTriggering();
  }
});
</script>

<template>
  <div
    v-if="isShowBanner"
    v-element-visibility="onElementVisibility"
    class="install-app-banner"
  >
    <div class="install-app-banner__wrapper">
      <div class="install-app-banner__close" @click="onClose">
        <CloseIcon />
      </div>

      <AppLogo class="install-app-banner__icon" />

      <div class="install-app-banner__text">
        <VText
          font="body-small-accent-low"
          class="install-app-banner__text_title"
        >
          Первый выбор
        </VText>
        <VText font="caption-large-regular-low">
          Карта В1 и купоны
        </VText>
      </div>
      <VButton
        v-if="pwaStore.state.appInstalled"
        class="install-app-banner__button"
        size="s"
        @click="onOpenPwaClick"
      >
        Открыть
      </VButton>
      <VButton
        v-else
        class="install-app-banner__button"
        :loading="pwaStore.state.installInProgress"
        size="s"
        @click="onButton"
      >
        Установить
      </VButton>
    </div>
  </div>
</template>

<style lang="postcss">
:root {
  --install-banner-height: 52px;
}

.install-app-banner {
  display: flex;
  width: 100vw;
  height: var(--install-banner-height);
  background: white;
  flex-shrink: 0;
  z-index: var(--pl-z-index-overlay);

  &__wrapper {
    padding: var(--pl-unit-x2) var(--pl-unit-x4);
    align-items: center;
    width: 100%;
    max-width: 1200px;
    transition: top 0.2s;
    height: var(--install-banner-height);
    display: flex;
    z-index: 5;
    background: white;
    border-bottom: 1px solid var(--pl-divider-primary);
  }

  &__close {
    display: flex;
  }

  &__icon {
    margin-left: var(--pl-unit-x1);
  }

  &__text {
    display: flex;
    flex-direction: column;
    margin-left: var(--pl-unit-x2);

    &_title {
      font-family: var(--pl-font-family-secondary);
    }
  }

  &__button {
    margin-left: auto;
  }
}

@media (--pl-viewport-from-ml) {
  .install-app-banner {
    display: none;
  }
}

@media all and (display-mode: standalone) {
  .install-app-banner {
    display: none;
  }
}
</style>
